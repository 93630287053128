/**
 * 使用可能な色の設定
 */
const color = {
  // main
  primary: "#0062B1",
  white: "#FFF",
  // accent
  attention: "#EE8F00",
  attentionLight: "#FDF4E5",
  warning: "#DE4335",
  emphasis: "#CB1706",
  blue: "#0071C1",
  lightBlue: "#F3F8FC",
  lightCyan: "#E4F2FA",
  whiteSmoke: "#FBFBFB",
  // text
  text: {
    black: "#23221F",
    gray: "#706D65",
    link: "#0071C1",
    linkDark: "#005A9A",
    linkLight: "#F2F8FC",
    disabled: "#C1BDB7",
    blue: "#0071C1",
  },
  background: "#F8F7F6",
  border: "#E5E5E5",
  footer: {
    background: "#EBECED",
  },
  form: {
    background: "#FBFBFB",
    border: "#A7A6A5",
    disabledBackground: "#E6E5E4",
  },
  table: {
    headerBackground: "#EDEBE8",
    headerBackgroundDark: "#E5E5E5",
  },
  root: {
    background: "#f6f6f6",
  },
};

export default color;
