import React, { useState, useEffect } from "react";

import UserContext from "contexts/UserContext";
import useGetUser from "hooks/api/useGetUser";
import useFirebaseContext from "hooks/useFirebaseContext";

const initialUser = {
  id: "",
  accountId: "",
  companyName: "",
  billingClosingDay: 0,
  contactId: "",
  email: "",
  isRequiredChangePassword: false,
  lastName: "",
  firstName: "",
  lastNameKana: "",
  firstNameKana: "",
  tel: "",
  fax: "",
  billingUnit: "everyContract" as const,
};
// 本体
const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useFirebaseContext();
  const { getUser } = useGetUser();
  const [user, setUser] = useState<User>(initialUser);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [isMonthlyBillingAccount, setIsMonthlyBillingAccount] = useState(false);

  const value = { user, setUser, isUserLoaded, isMonthlyBillingAccount };

  useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged(async (newAuth) => {
      if (newAuth) {
        const user = await getUser();
        setUser(user);
        setIsMonthlyBillingAccount(user.billingUnit === "monthly");
      } else {
        // ログアウトした
        setUser(initialUser);
      }
      setIsUserLoaded(true);
    });
    return unsubscribed;
  }, [auth, getUser]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
