import React from "react";
import styled from "styled-components";

import color from "constants/color";

const Overlay = styled.div<{ isVisible: boolean; paddingHorizontal?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ paddingHorizontal = 0 }) => `calc(100% - ${paddingHorizontal * 2}px)`};
  height: 100%;
  padding: 0 ${({ paddingHorizontal = 0 }) => paddingHorizontal}px;
  background-color: rgba(0, 0, 0, 0.25);
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 3;
`;
const Content = styled.div<{ width?: string; height?: string; paddingHorizontal?: number; paddingVertical?: number }>`
  width: ${({ paddingHorizontal = 0, width = "400px" }) => `calc(${width} - ${paddingHorizontal * 2}px)`};
  height: ${({ height = "250px" }) => height};
  padding: ${({ paddingVertical = 0 }) => paddingVertical}px ${({ paddingHorizontal = 0 }) => paddingHorizontal}px;
  border-radius: 8px;
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const stopPropagation = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();

// 本体
const Modal = ({
  isVisible,
  onClose,
  children,
  width,
  height,
  paddingHorizontal,
  paddingVertical,
}: {
  isVisible: boolean;
  onClose: () => void;
  children: JSX.Element;
  width?: string;
  height?: string;
  paddingHorizontal?: number;
  paddingVertical?: number;
}) => {
  return (
    <Overlay onClick={onClose} isVisible={isVisible} paddingHorizontal={paddingHorizontal}>
      <Content
        width={width}
        height={height}
        onClick={stopPropagation}
        paddingHorizontal={paddingHorizontal}
        paddingVertical={paddingVertical}
      >
        {children}
      </Content>
    </Overlay>
  );
};

export default Modal;
