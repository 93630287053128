import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback } from "react";

import { API_URL } from "constants/api";
import useFirebaseContext from "hooks/useFirebaseContext";

const useClient = () => {
  const { auth } = useFirebaseContext();
  const client = useCallback(
    async <T, D>(config: AxiosRequestConfig): Promise<AxiosResponse<T, D>> => {
      const token = await auth.currentUser?.getIdToken();
      if (!token) throw new Error("401 Unauthorized");

      const response = await axios
        .request<T>({
          method: config.method,
          url: `${API_URL}${config.url}`,
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: config.params ?? undefined,
          data: config.data ?? undefined,
        })
        .catch((e) => {
          throw e;
        });

      return response;
    },
    [auth?.currentUser]
  );
  return { client };
};

export default useClient;
