import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import {
  faUser,
  faAngleDown,
  faKey,
  faRightFromBracket,
  faClipboardCheck,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import atportLogo from "atport_logo.svg";
import Button from "components/Button";
import Popup from "components/Popup";
import color from "constants/color";
import font from "constants/font";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import useSharedModalContext from "hooks/useSharedModalContext";
import useUserContext from "hooks/useUserContext";
import { copyOnClipboard } from "utils/clipboardUtils";

// パーツ定義
const Wrap = styled.header`
  height: 60px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  background-color: ${color.white};
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  & > button {
    margin-left: auto;
  }
`;
const Logo = styled.img`
  width: 88px;
  padding: 16px 30px;
  border-right: solid 1px ${color.border};
  :hover {
    cursor: pointer;
  }
`;
const NavigationArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 0 32px;
`;
const Link = styled.span<{ isCurrent?: boolean; budge?: boolean }>`
  position: relative;
  height: 60px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  color: ${({ isCurrent }) => (isCurrent ? color.text.black : color.text.gray)};
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
  // 通知の丸
  :after {
    display: ${({ budge }) => (budge ? "block" : "none")};
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    top: 14px;
    right: -2px;
    z-index: 1;
    background-color: ${color.attention};
  }
  :before {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 0;
    background: ${color.text.link};
    transform: ${({ isCurrent }) => (isCurrent ? "scale(1, 1)" : "scale(1, 0)")};
    transform-origin: center bottom;
    transition: transform 0.3s;
  }
  :hover:before {
    transform: scale(1, 1);
  }
  :hover {
    color: ${color.text.black};
  }
`;
const MenuList = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  margin-left: 10px;
  min-width: 160px;
  height: 100%;
  box-sizing: border-box;
  transition: 0.3s;
  :before {
    position: absolute;
    content: "";
    top: 12px;
    left: 0;
    width: 1px;
    height: 36px;
    background-color: ${color.border};
  }
  :hover {
    cursor: pointer;
    color: ${color.text.black};
    background-color: ${color.background};
  }
`;
const UserIconWrap = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: ${color.text.gray};
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const UserName = styled.span`
  font-size: ${font.size14};
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
const AngleDownIcon = styled(FontAwesomeIcon)`
  color: ${color.text.gray};
  margin-left: 10px;
`;
const SubNavigationArea = styled.div<{ hidden: boolean }>`
  position: absolute;
  width: 100%;
  top: 60px;
  left: 0;
  z-index: 2;
  background-color: ${color.white};
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  flex-direction: column;
  border-radius: 0 0 0 4px;
  box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.07), 7px 7px 14px -7px rgba(0, 0, 0, 0.07);
`;
const SubLink = styled.span`
  height: 53px;
  font-size: ${font.size14};
  padding-left: 16px;
  border-top: 1px solid ${color.border};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: ${color.text.gray};
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: ${color.text.black};
    background-color: ${color.background};
  }
`;
const SubLinkOfContactId = styled.span`
  font-size: ${font.size14};
  border-top: 1px solid ${color.border};
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: ${color.text.gray};
  transition: 0.3s;
  padding: 10px;
  :hover {
    cursor: auto;
    color: ${color.text.black};
    background-color: ${color.background};
  }
`;
const ContactIdWrap = styled.div`
  padding: 8px;
  min-width: 80%;
  background: ${color.background};
  font-size: ${font.size12};
  border-radius: 4px;
  margin-bottom: 10px;
  transition: 0.3s;
  ${SubLinkOfContactId}:hover & {
    background-color: ${color.white};
  }
`;
const ContactIdLabel = styled.div`
  padding: 8px 0 8px 0;
  color: ${color.text.disabled};
  font-size: ${font.size12};
`;
const ContactIdBody = styled.div`
  width: fit-content;
  display: flex;
  font-size: ${font.size12};
  justify-content: space-between;
  width: 100%;
`;
const IconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${color.text.link};
`;
const IconLabel = styled.div`
  font-size: ${font.size12};
`;
const PopupHelper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const Header = () => {
  const { user } = useUserContext();
  const [isOpened, setIsOpened] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { sendEvent } = useGoogleAnalytics(true);
  const { showModal } = useSharedModalContext();

  const navRoot = () => navigate("/");
  const navOrderCreate = () => {
    showModal({ modalType: "OrderMethodModal" });
  };
  const navOrder = () => {
    sendEvent({
      action: "検索依頼_ヘッダー",
      category: "ヘッダー",
      label: location.pathname.replace(/\/\w{18}($|\/)/, "/detail$1"),
    });
    navigate("/order");
  };
  const navPassword = () => navigate("password");
  const navMfa = () => navigate("mfa");

  const navBilling = () => {
    sendEvent({
      action: "請求一覧_ヘッダー",
      category: "ヘッダー",
      label: location.pathname.replace(/\/\w{18}($|\/)/, "/detail$1"),
    });
    navigate("billing");
  };
  const navUsage = () => {
    sendEvent({
      action: "利用状況_ヘッダー",
      category: "ヘッダー",
      label: location.pathname.replace(/\/\w{18}($|\/)/, "/detail$1"),
    });
    navigate("usage");
  };
  const logout = () => {
    const auth = getAuth();
    signOut(auth);
    navigate("login");
  };

  const [copied, setCopied] = useState(false);

  return (
    <Wrap>
      <Logo src={atportLogo} alt="at PORT" onClick={navRoot} />
      <NavigationArea>
        <Link onClick={navRoot} isCurrent={"/" === location.pathname}>
          ホーム
        </Link>
        <Link onClick={navOrder} isCurrent={/^\/order\/*/.test(location.pathname)}>
          検索依頼
        </Link>
        <Link onClick={navBilling} isCurrent={/^\/billing\/*/.test(location.pathname)}>
          請求一覧
        </Link>
        <Link onClick={navUsage} isCurrent={/^\/usage\/*/.test(location.pathname)}>
          利用状況
        </Link>
      </NavigationArea>

      <Button type="primary" onClick={navOrderCreate} label="駐車場検索を依頼" height="44px" />
      <MenuList onClick={() => setIsOpened(!isOpened)}>
        <UserIconWrap>
          <FontAwesomeIcon icon={faUser} />
        </UserIconWrap>
        <UserName>{user.lastName + user.firstName}</UserName>
        <AngleDownIcon icon={faAngleDown} />
        <SubNavigationArea onMouseLeave={() => setIsOpened(!isOpened)} hidden={!isOpened}>
          <SubLinkOfContactId>
            <ContactIdBody>
              <ContactIdLabel>企業ID</ContactIdLabel>
              <IconWrap>
                <Popup
                  onClick={() => copyOnClipboard(user.accountId, setCopied(true))}
                  fontSize={font.size12}
                  right="-6px"
                  top="-32px"
                  text="コピーしました"
                >
                  <PopupHelper>
                    <FontAwesomeIcon icon={!copied ? faClipboard : faClipboardCheck} />
                    <IconLabel>コピー</IconLabel>
                  </PopupHelper>
                </Popup>
              </IconWrap>
            </ContactIdBody>
            <ContactIdWrap>{user.accountId}</ContactIdWrap>
          </SubLinkOfContactId>
          <SubLink onClick={navPassword}>
            <FontAwesomeIcon icon={faKey} />
            パスワード変更
          </SubLink>
          <SubLink onClick={navMfa}>
            <FontAwesomeIcon icon={faUserCircle} />
            2段階認証設定
          </SubLink>
          <SubLink onClick={logout}>
            <FontAwesomeIcon icon={faRightFromBracket} />
            ログアウト
          </SubLink>
        </SubNavigationArea>
      </MenuList>
    </Wrap>
  );
};

export default Header;
