import axios from "axios";
import { Auth } from "firebase/auth";
import useSWR, { KeyedMutator } from "swr";

import useFirebaseContext from "hooks/useFirebaseContext";

export type AxiosErrorData = {
  error: string;
  message: string;
  statusCode: number;
};

type AxiosError = {
  response?: {
    data?: AxiosErrorData;
  };
};

const isDevelopEnv = ["local", "dev", "staging"].includes(process.env.REACT_APP_ENV ?? "");

/**
 * swr で api を取得するのに使用する
 * @param url: string
 * @param auth: Auth
 * @returns
 */
const fetcher = async (url: string | null, auth: Auth) => {
  if (!url) return;
  const token = await auth?.currentUser?.getIdToken();

  axios.interceptors.response.use(
    (response) => {
      if (isDevelopEnv) console.log("レスポンスデータ：", response.data);
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  const response = await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error: AxiosError) => {
      if (isDevelopEnv) console.error("エラー：", error?.response?.data ?? error);
      throw error;
    });

  return response.data;
};
type swrOptions = {
  revalidateIfStale?: boolean;
  revalidateOnFocus?: boolean;
  revalidateOnReconnect?: boolean;
  onErrorRetry?: (error: AxiosError) => void;
};

/**
 * useSWR を使用する
 * @param url
 * @returns
 */
/**
 * https://swr.vercel.app/ja/docs/options
 * SalesforceのAPIに回数制限があるのでデフォルトでは再検証系はfalseにする
 */
export const useSwr = <T>(
  url: string | null,
  options?: swrOptions
): {
  data: T;
  error: Error;
  mutate: KeyedMutator<T>;
  isLoading?: boolean;
  isValidating?: boolean;
} => {
  const { auth } = useFirebaseContext();
  const { data, error, mutate, isValidating } = useSWR([url, auth], ([url, auth]) => fetcher(url, auth), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    ...options,
  });

  return { data, error, mutate, isValidating };
};
