import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faClipboardCheck, faKey, faRightFromBracket, faUser, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth, signOut } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import atportLogo from "atport_logo.svg";
import Button from "components/Button";
import Modal from "components/Modal";
import Popup from "components/Popup";
import color from "constants/color";
import font from "constants/font";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import useSharedModalContext from "hooks/useSharedModalContext";
import useUserContext from "hooks/useUserContext";
import { copyOnClipboard } from "utils/clipboardUtils";

const Wrap = styled.header`
  background-color: ${color.white};
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
`;

const Container = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid ${color.border};
  & > button {
    margin-left: auto;
  }
`;

const Logo = styled.img`
  width: 88px;
  padding: 16px 16px;
`;

const MenuList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
  :before {
    position: absolute;
    content: "";
    top: 12px;
    left: 0;
    width: 1px;
    height: 36px;
  }
  :hover {
    color: ${color.text.black};
    background-color: ${color.background};
  }
`;

const NavigationArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-around;
  gap: 32px;
  padding: 0 32px;
`;

const UserIconWrap = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: ${color.text.gray};
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Link = styled.span<{ isCurrent?: boolean; budge?: boolean }>`
  position: relative;
  height: 52px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isCurrent }) => (isCurrent ? color.text.black : color.text.gray)};
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
  // 通知の丸
  :after {
    display: ${({ budge }) => (budge ? "block" : "none")};
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    top: 14px;
    right: -2px;
    z-index: 1;
    background-color: ${color.attention};
  }
  :before {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 0;
    background: ${color.text.link};
    transform: ${({ isCurrent }) => (isCurrent ? "scale(1, 1)" : "scale(1, 0)")};
    transform-origin: center bottom;
    transition: transform 0.3s;
  }

  @media (hover: hover) {
    :hover:before {
      transform: scale(1, 1);
    }
    :hover {
      color: ${color.text.black};
    }
  }

  @media (hover: none) {
    :active:before {
      transform: scale(1, 1);
    }
    :active {
      color: ${color.text.black};
    }
  }
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  height: 73px;
  border-bottom: solid 1px ${color.border};
`;

const ProfileModalContainer = styled.div`
  display: block;
  width: 100%;
  padding: 8px 16px 20px 16px;
  box-sizing: border-box;
`;

const ContextWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-size: 19.2px;
  color: ${color.text.gray};
  margin-left: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 160px;
  margin: auto;
  padding-top: 18px;
`;

const ContactIdContainer = styled.span`
  border-bottom: 1px solid ${color.border};
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: ${color.text.gray};
  transition: 0.3s;
  padding: 0 8px 0 8px;
`;

const ContactIdWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  padding-left: 10px;
  background: ${color.background};
  border-radius: 4px;
  margin: 0 0 16px 0;
  border: 1px solid ${color.border};
`;

const ContactIdLabel = styled.div`
  color: ${color.text.gray};
`;

const ContactIdBody = styled.div`
  width: 300px;
  display: flex;
  font-size: ${font.size14};
  justify-content: space-between;
  margin: 16px 0px 6px 12px;
`;

const IconWrap = styled.div`
  width: 52px;
  height: 100%;
  padding: 10px;
  background: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.text.link};
  gap: 4px;
  border-left: 1px solid ${color.border};
`;

const IconLabel = styled.div`
  cursor: pointer;
  font-size: ${font.size12};
`;

const ContactId = styled.p`
  font-size: ${font.size14};
`;

const ProfileModal = ({
  isVisible,
  onCloseHandler,
  navPassword,
  navMfa,
  logout,
}: {
  isVisible: boolean;
  onCloseHandler: () => void;
  navPassword: () => void;
  navMfa: () => void;
  logout: () => void;
}) => {
  const { user } = useUserContext();

  const [copied, setCopied] = useState(false);

  return (
    <Modal isVisible={isVisible} onClose={onCloseHandler} width="343px" height="auto">
      <ProfileModalContainer>
        <ContactIdContainer>
          <ContactIdBody>
            <ContactIdLabel>企業ID</ContactIdLabel>
          </ContactIdBody>
          <ContactIdWrap>
            <ContactId>{user.accountId}</ContactId>
            <Popup
              onClick={() => copyOnClipboard(user.accountId, setCopied(true))}
              right="-12px"
              top="-40px"
              fontSize={font.size12}
              text="コピーしました"
            >
              <IconWrap>
                <FontAwesomeIcon fill={"red"} size="sm" icon={!copied ? faClipboard : faClipboardCheck} />
                <IconLabel>コピー</IconLabel>
              </IconWrap>
            </Popup>
          </ContactIdWrap>
        </ContactIdContainer>
        <RowWrapper>
          <ContextWrapper
            onClick={() => {
              navPassword();
              onCloseHandler();
            }}
          >
            <FontAwesomeIcon icon={faKey} color={color.text.gray} />
            <Text>パスワード変更</Text>
          </ContextWrapper>
        </RowWrapper>
        <RowWrapper>
          <ContextWrapper
            onClick={() => {
              navMfa();
              onCloseHandler();
            }}
          >
            <FontAwesomeIcon icon={faUserCircle} color={color.text.gray} />
            <Text>2段階認証設定</Text>
          </ContextWrapper>
        </RowWrapper>
        <RowWrapper>
          <ContextWrapper
            onClick={() => {
              logout();
              onCloseHandler();
            }}
          >
            <FontAwesomeIcon icon={faRightFromBracket} color={color.text.gray} />
            <Text>ログアウト</Text>
          </ContextWrapper>
        </RowWrapper>

        <ButtonWrapper>
          <Button label="閉じる" type="secondary" width="100%" onClick={onCloseHandler} />
        </ButtonWrapper>
      </ProfileModalContainer>
    </Modal>
  );
};

const HeaderForSp = () => {
  const { sendEvent } = useGoogleAnalytics(true);
  const { showModal } = useSharedModalContext();

  const navigate = useNavigate();
  const navRoot = () => navigate("/");
  const navOrderCreate = () => showModal({ modalType: "OrderMethodModal" });

  const navOrder = () => {
    sendEvent({
      action: "検索依頼_ヘッダー",
      category: "ヘッダー",
      label: location.pathname.replace(/\/\w{18}($|\/)/, "/detail$1"),
    });
    navigate("/order");
  };
  const navBilling = () => {
    sendEvent({
      action: "請求一覧_ヘッダー",
      category: "ヘッダー",
      label: location.pathname.replace(/\/\w{18}($|\/)/, "/detail$1"),
    });
    navigate("billing");
  };
  const navUsage = () => {
    sendEvent({
      action: "利用状況_ヘッダー",
      category: "ヘッダー",
      label: location.pathname.replace(/\/\w{18}($|\/)/, "/detail$1"),
    });
    navigate("usage");
  };
  const navPassword = useCallback(() => navigate("password"), [navigate]);
  const navMfa = useCallback(() => navigate("mfa"), [navigate]);

  const [isVisible, setIsVisible] = useState(false);
  const logout = () => {
    const auth = getAuth();
    signOut(auth);
    navigate("login");
  };

  // モーダル表示時に背面のスクロール抑制
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isVisible]);

  return (
    <Wrap>
      <Container>
        <Logo src={atportLogo} alt="at PORT" onClick={navRoot} />
        <Button
          type="primary"
          width="144px"
          paddingHorizontal="8px"
          onClick={navOrderCreate}
          label="駐車場検索を依頼"
          height="44px"
        />
        <MenuList onClick={() => setIsVisible(!isVisible)}>
          <UserIconWrap>
            <FontAwesomeIcon icon={faUser} />
          </UserIconWrap>
        </MenuList>
        <ProfileModal
          isVisible={isVisible}
          onCloseHandler={() => setIsVisible(false)}
          navPassword={navPassword}
          navMfa={navMfa}
          logout={logout}
        />
      </Container>
      <NavigationArea>
        <Link onClick={navOrder} isCurrent={/^\/order\/*/.test(location.pathname)}>
          検索依頼
        </Link>
        <Link onClick={navBilling} isCurrent={/^\/billing\/*/.test(location.pathname)}>
          請求一覧
        </Link>
        <Link onClick={navUsage} isCurrent={/^\/usage\/*/.test(location.pathname)}>
          利用状況
        </Link>
      </NavigationArea>
    </Wrap>
  );
};

export default HeaderForSp;
