import queryString from "query-string";
import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";
import useUserContext from "hooks/useUserContext";
import { OrderStatusFilter } from "pages/OrderList/orderStatusFilter";

export type FindOrdersParams = {
  pageSize: number;
  pageNumber: number;
  orderType: "asc" | "desc";
  orderStatusFilter: OrderStatusFilter;
  contactId?: string;
  department?: string;
  searchKeyword?: string;
};
const initialPagination = {
  pageSize: 100,
  pageIndex: 1,
  totalPages: 1,
  totalRecords: 100,
};

const useFindOrders = (
  params: FindOrdersParams
): {
  orders: Order[] | undefined;
  pagination: Pagination;
  error: Error;
  mutate: KeyedMutator<OrderResponse>;
} => {
  const { user } = useUserContext();
  const { data, error, mutate } = useSwr<OrderResponse>(
    `${API_URL}/order?${queryString.stringify({ ...params, billingUnit: user.billingUnit })}`,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  if (!data || error)
    return {
      orders: undefined,
      pagination: initialPagination,
      error,
      mutate,
    };

  return { orders: data.data, pagination: data.pagination, error, mutate };
};

export default useFindOrders;
