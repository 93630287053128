import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import Button from "components/Button";
import Modal from "components/Modal";
import color from "constants/color";
import font from "constants/font";

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;
const Error = styled.div`
  width: 400px;
  height: 98px;
  border-radius: 8px 8px 0 0;
  color: ${color.white};
  background-color: ${color.attention};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ExclamationIcon = styled(FontAwesomeIcon)`
  height: 23px;
  width: 23px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TitleMessage = styled.span`
  font-size: ${font.size20};
`;
const ActionArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin: 20px 0;
`;

const errorMessage = (httpMethod: HttpMethod | undefined) => {
  switch (httpMethod) {
    case "post":
      return "送信に失敗しました";
    case "get":
      return "取得に失敗しました";
    default:
      return "通信に失敗しました";
  }
};

/**
 *
 * HttpでGet/Postに失敗したときに表示するモーダル
 */
const ErrorModal = ({
  isVisible,
  onClose,
  httpMethod,
}: {
  isVisible: boolean;
  onClose: () => void;
  httpMethod: HttpMethod;
}) => {
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <>
        <Body>
          <Error>
            <ExclamationIcon icon={faCircleExclamation} />
            <TitleMessage>{errorMessage(httpMethod)}</TitleMessage>
          </Error>
          <span>時間をおいてもう一度お試しください</span>
        </Body>
        <ActionArea>
          <Button label="閉じる" onClick={onClose} type="secondary" width="160px" />
        </ActionArea>
      </>
    </Modal>
  );
};

export default ErrorModal;
