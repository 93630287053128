import { useState } from "react";

import BaseErrorModal from "components/ErrorModal";
import BaseInfoModal from "components/InfoModal";
import BaseModal from "components/Modal";
import BaseNoticeModal from "components/NoticeModal";
import BaseWarningModal from "components/WarningModal";

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const showModal = () => setIsVisible(true);
  const hideModal = () => setIsVisible(false);

  const Modal = ({ children }: { children: JSX.Element }) => {
    return (
      <BaseModal isVisible={isVisible} onClose={hideModal}>
        {children}
      </BaseModal>
    );
  };

  return { Modal, showModal, hideModal };
};

export const useErrorModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const showErrorModal = () => setIsVisible(true);
  const hideErrorModal = () => setIsVisible(false);

  const ErrorModal = ({ httpMethod }: { httpMethod: HttpMethod }) => {
    return <BaseErrorModal isVisible={isVisible} onClose={hideErrorModal} httpMethod={httpMethod} />;
  };

  return { ErrorModal, showErrorModal, hideErrorModal };
};

export const useWarningModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const showWarningModal = () => setIsVisible(true);
  const hideWarningModal = () => setIsVisible(false);

  const WarningModal = ({
    title,
    label,
    onClick,
  }: {
    title: string;
    label: string;
    onClick: () => Promise<void> | void;
  }) => {
    return (
      <BaseWarningModal
        isVisible={isVisible}
        onClose={hideWarningModal}
        title={title}
        label={label}
        onClick={onClick}
      />
    );
  };
  return { WarningModal, showWarningModal, hideWarningModal };
};

export const useNoticeModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const showNoticeModal = () => setIsVisible(true);
  const hideNoticeModal = () => setIsVisible(false);

  const NoticeModal = ({
    title,
    message,
    buttonText,
    onClick,
  }: {
    title: string;
    message: string | JSX.Element;
    buttonText?: string;
    onClick?: () => Promise<void> | void;
  }) => {
    return (
      <BaseNoticeModal
        isVisible={isVisible}
        onClose={hideNoticeModal}
        title={title}
        message={message}
        buttonText={buttonText}
        onClick={onClick}
      />
    );
  };

  return { NoticeModal, showNoticeModal, hideNoticeModal };
};

export const useInfoModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const showInfoModal = () => setIsVisible(true);
  const hideInfoModal = () => setIsVisible(false);

  const InfoModal = ({ title, onClick }: { title: string; onClick?: () => Promise<void> | void }) => {
    return <BaseInfoModal isVisible={isVisible} onClose={hideInfoModal} title={title} onClick={onClick} />;
  };

  return { InfoModal, showInfoModal, hideInfoModal };
};
